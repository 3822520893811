import '../styles/privacy.scss'

import { graphql } from 'gatsby'
import React from 'react'
import { StructuredText } from 'react-datocms'

import Layout from './Layout'

const LegalPageLayout = ({ data: { datoCmsLegalPage: page } }) => {
  console.log(page)
  return (
    <Layout id="privacy-page">
      <section id="policy">
        {
          <StructuredText
            data={page.content}
            renderBlock={({ record }) => {
              switch (record.__typename) {
                case 'DatoCmsAssetLink':
                  return (
                    <a
                      href={record.asset.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="cta"
                    >
                      {record.linkText}
                    </a>
                  )
                default:
                  return null
              }
            }}
          />
        }
      </section>
    </Layout>
  )
}

export default LegalPageLayout

export const data = graphql`
  query ($slug: String!) {
    datoCmsLegalPage(slug: { eq: $slug }) {
      content {
        value
        blocks {
          ... on DatoCmsAssetLink {
            __typename
            id: originalId
            linkText
            asset {
              url
            }
          }
        }
      }
    }
  }
`
